<template>
    <div class="row">
        <div v-for="pedido in pedidos" :key="pedido.id" class="col-md-6 col-lg-4 col-xl-3 grid-margin stretch-card">
            <div  class="card">
                <div class="card-body">
                    <h4 class="card-title">{{pedido.codigo}}</h4>
                    <div class="media">
                        <div class="media-body" style="width: 100%">
                            <div class="progress mt-4">
                                <div
                                    class="progress-bar" :class="corPorcPagamentoPedido(pedido.status.id_status_pagamento)"
                                    role="progressbar"
                                    :style="{'width': valorPorcPagamentoPedido(pedido.status.id_status_pagamento) + '%'}"
                                    :aria-valuenow="valorPorcPagamentoPedido(pedido.status.id_status_pagamento)"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>

                            <p class="card-text mt-4">
                                <b>CLIENTE:</b> {{pedido.cliente.nome}}
                            </p>

                            <p class="card-text mt-2">
                                <b>CELULAR:</b> {{pedido.cliente.celular}}
                            </p>

                            <p class="card-text mt-2">
                                <b>E-MAIL:</b> {{pedido.cliente.email}}
                            </p>

                            <hr>

                            <p class="card-text mt-2">
                                <b>Status Pedido:</b> {{pedido.status.pedido}}
                            </p>

                            <p class="card-text mt-2">
                                <b>Status Pagamento:</b> {{pedido.status.pagamento}}
                            </p>

                            <p class="h3 card-text mt-4">
                                {{pedido.valor.total_formatado}}
                            </p>

                            <router-link
                                :to="{ name: 'DetalhesPedidos', params : {id : pedido.id} }"
                                class="btn btn-primary mt-3"
                            >
                                Detalhes</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="paginacao.totalPaginas > 0" class="d-flex align-items-center justify-content-center">
            <pagination v-model="paginacao.pagina" :records="paginacao.total" :per-page="8" :options="opcao" @paginate="paginaEscolhida"/>
        </div>

        <div v-if="paginacao.totalPaginas == 0" class="alert alert-warning" role="alert">
            Não há pedidos no momento
        </div>

    </div>
</template>

<script>
import axiosApi from '@/axios'
import store from '@/store'
import Pagination from 'vue-pagination-2'
export default {
    components:{
        Pagination
    },

    data(){
        return{
            paginacao:{
                total: 1,
                pagina:1,
                totalPaginas: 1
            },

            opcao:{
                edgeNavigation: false,
                texts: { count: '' }
            },

            pedidos: []
        }
    },

    methods:{
        async paginaEscolhida(){
            await this.listarPedidos()
            window.scrollTo(0,0)
        },

        corPorcPagamentoPedido(status){
            let valor = ''
            switch (status){
                case 1:
                    valor = 'bg-warning'
                    break
                case 2:
                    valor = 'bg-success'
                    break
                case 3:
                    valor = 'bg-danger'
                    break
            }
            return valor
        },

        valorPorcPagamentoPedido(status){
            let valor = ''
            switch (status){
                case 1:
                    valor = 50
                    break
                case 2:
                    valor = 100
                    break
                case 3:
                    valor = 100
                    break
            }
            return valor
        },

        async listarPedidos(){
          try {
                store.commit('setLoading', true)
                const response = await axiosApi.get(`/admin/pedido?ordem=desc&pag=${this.paginacao.pagina}`)
                this.paginacao.totalPaginas = response.data.total_paginas
                this.paginacao.total = response.data.total
                this.pedidos = response.data.pedidos
                console.log(this.paginacao)
            } finally {
                store.commit('setLoading', false)
            }
        }
    },

    async mounted(){
        await this.listarPedidos()
    }
};
</script>

<style>
</style>